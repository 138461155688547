<template>
  <div class="main-content">
    <div style="position: relative" class="content">
      <el-container>
        <CommonTreeNew
          ref="commonTree"
          treeTitle="组织架构"
          :defaultProps="Props"
          :showCheckbox="false"
          @getNodeClick="handleNode"
          :urlParmas="{
            tenantId: '',
            deptCategory: '5,2',
            parentId: userInfo.dept_id,
          }"
          node-key="id"
          :isShowdig="false"
          :isZoom="true"
          :show="show"
          @showChange="showChange"
        ></CommonTreeNew>

        <el-main style="position: relative">
          <head-layout
            :head-btn-options="headBtnOptions"
            head-title="风险辨识"
            @head-add="opDialog()"
            @head-delete="deletesList"
          >
          </head-layout>
          <grid-head-layout
            ref="searchForm"
            :searchSpan="'4'"
            :search-columns="searchColumns"
            @grid-head-search="handleSearch"
            @grid-head-empty="searchReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
          >
            <template #customBtn="{ row, index }">
              <el-button
                type="text"
                size="small"
                v-if="row.createUser == userInfo.user_id"
                @click="rowEdit(row, index)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="linkDetail(row, index)"
              >
                查看
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="row.createUser == userInfo.user_id"
                @click="rowDel(row, index)"
              >
                删除
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
    </div>

    <el-dialog
      title="风险版本配置"
      :modal-append-to-body="false"
      :visible.sync="showTreeDialog"
      width="500px"
      class="qmDialog"
    >
      <avue-form
        v-if="showTreeDialog"
        :option="treeDialogOption"
        ref="addForm"
        v-model="treeForm"
        @submit="handleSubmit"
      ></avue-form>
      <span slot="footer">
        <el-button
          size="small"
          @click="cancelTreeDialog"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
          v-loading="treeDialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteTree,
  riskVersionAdd,
  riskVersionDelete,
  getSourcetree,
  getPageRiskManagementAndControl,
  riskVersionPageByOrgId,
} from "@/api/riskManage/project";

import ProcessUserDialog from "@/views/business/components/processUserDialog.vue";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {
  processList as getProcessList,
  startProcess,
} from "@/api/plugin/workflow/process";
import {
  getTree as riskTreeList,
  getList as riskTableList,
} from "@/api/safetyTtandard/riskIdentification";
 
import { mapGetters } from "vuex";
import ServerNameEnum from "@/util/ServerNameEnum";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  name: 'riskProject',
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    ProcessUserDialog,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    treeDialogOption() {
      return {
        size: "small",
        menuFixed: "right",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "风险版本名称",
            prop: "riskVersionName",
            type: "input",
            maxlength: 64,
            overHidden: true,
            span: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "辨识人",
            prop: "createUserName",
            type: "input",
            span: 24,
            disabled: true,
            rules: [
              {
                required: true,
                message: "请输入辨识人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "辨识时间",
            prop: "createTime",
            type: "datetime",
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输选择",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    headBtnOptions() {
      let result = [];
      result.push({
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
      });
      result.push({
        label: "删除",
        emit: "head-delete",
        type: "button",
        icon: "",
      });

      return result;
    },
  },
  mixins: [exForm],
  data() {
    return {
      treeNode: {},
      Props: {
        children: "children",
        label: "title",
      },
      prjSearchTitle: "title",
      excelBox: false,
      riskVersionId: "",
      quarterActive: 0,

      introduceType: "",
      parentIdText: true,
      parentIdData: [],
      quarterForm: {
        riskVersionName: "",
      },

      riskSource: true,
      quarterItme: {},
      show: true,
      //禁用
      disabledErpItemList: [],
      processData: {},
      releaseRow: {},
      quarterFormRules: {
        riskVersionName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
      },
      quarterList: [],
      defaultCheckedKeys: [],

      companySelection: [],
      introduceTableData: [],
      introduceQuery: {},

      introducePage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      treeForm: {  },
      searchColumns: [
        { prop: "riskVersionName", span: 4, placeholder: "请输入风险版本名称" },
      ],

      tableOptions: {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 200,
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        selectable: (row) => {
          return true;
        },
        column: [
          {
            label: "版本名称",
            prop: "riskVersionName",
            align: "center",
            overHidden: true,
            minWidth: 200,
          },
          {
            label: "辨识人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
        ],
      },
      showTreeDialog: false,
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "sourceTypeName",
      },
      companydefaultProps: {
        children: "children",
        id: "id",
        label: "title",
      },
      tableTotal: 0,
      tableLoading: false,
      treeArrData: [],
      isSource: false,
      option: {},
      nowTreeNode: {},
      ids: "",
      D: [],
      query: {},

      nowNodeId: "",
      defaultExpandedKeys: [],
      introduceExpandedKeys: [],
      searchForm: {},
    };
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".riskClass .el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {},
  activated () {
    if(this.treeNode){
      this.onload(this.page);
    }
  },
  methods: {
    showChange(v) {
      this.show = v;
    },
    selectable(row) {
      return this.disabledErpItemList.indexOf(row.id) === -1;
    },
    handleNode(node) {
      this.quarterItme = {};
      this.treeNode = node;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    handleSearch(searchForm) {
      this.searchForm = searchForm;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    onload(page) {
      this.tableLoading = true
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
        ...this.searchForm,
      };
      riskVersionPageByOrgId(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    getProcessData() {
      getProcessList(1, 100).then((res) => {
        let data = res.data.data.records;
        data.forEach((item) => {
          if (item.key == "risk_management_project_process") {
            this.processData = item;
          }
        });
      });
    },

    linkDetail(row) {
      this.$router.push({
        path: "/riskProject/riskVersionDetail",
        query: {
          type: "detail",
          riskVersionId: row.id,
          organizationId: row.organizationId,
          riskVersionName: row.riskVersionName
        },
      });
    },

    computedDlevel(levelCode) {
      let result = this.D.find((item) => item.levelCode == levelCode);
      if (result) {
        result.bg =
          result.color.substring(0, 3) +
          result.color.substring(3, result.color.length - 2) +
          "0.1)";
      }

      return (
        result || {
          color: "rgba(252, 233, 119, 1)",
          bg: "rgba(252, 233, 119, 0.1)",
        }
      );
    },
    getNodeId(clo, id) {
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        this.treeForm.parentId = clo.value;
        const result = this.treeArrData.find((item) => item.id == clo.value);
        if (result != undefined) {
          this.isSource = result.isSource || false;
        }
        this.nowTreeNode = result || {};
      }
    },
    cancelTreeDialog(){
      this.showTreeDialog = false
      this.treeForm = {}
    },
    //编辑
    rowEdit(row) {
      this.treeForm = row
      this.showTreeDialog = true
    },

    rowDel(row) {
      this.$confirm("确认删除当前选中数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTree({ ids: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.page.currentPage = 1;
              this.onload(this.page);
            }
          });
        })
        .catch((error) => {
          this.$message({
            message: "取消删除",
            type: "success",
          });
        });
    },

    //树
    getdemotree() {
      getSourcetree({
        organizationId: this.treeNode.id,
      }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.defaultExpandedKeys = this.treeData.map((item) => {
            return item.id;
          });
          this.treeArrData = this.flattenTree(res.data.data);
          if (this.treeData.length != 0) {
            this.defaultCheckedKeys.push(this.treeData[0].id);
          } else {
            this.nowNodeId = undefined;
            this.isSource = false;
            this.tableData = [];
            this.tableTotal = 0;
          }
        }
      });
    },

    //扁平化 树
    flattenTree(tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = { ...node, parent };
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    opDialog() {
      this.treeForm.createUser = this.userInfo.user_id
      this.treeForm.createUserName = this.userInfo.user_name
      this.showTreeDialog = true;
    },

    deletesList() {
      if (this.ids) {
        this.$confirm("确认删除当前选中数据吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            riskVersionDelete({ ids: this.ids }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.page.currentPage = 1;
                this.onload(this.page);
              }
            });
          })
          .catch((error) => {
            this.$message({
              message: "取消删除",
              type: "success",
            });
          });
      } else {
        this.$message({
          message: "请选择需要删除的数据",
          type: "warning",
        });
      }
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      if (data.type == "1") {
        this.riskSource = true;
      } else {
        this.riskSource = false;
      }
      this.quarterItme = data;
      this.riskVersionId = this.quarterItme.riskVersionId;
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.tableLoading = true;
      getPageRiskManagementAndControl(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },

    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onload(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onload(this.page);
    },

    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },

    searchReset() {
      this.searchForm = {};
      this.page.currentPage = 1;
      this.onload(this.page);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    handleTreeNodeSave() {
      this.$refs.addForm.submit()
      
    },
    handleSubmit (data, done) {
      // 新增树节点
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          riskVersionAdd({
            ...this.treeForm,
           // createDept: this.userInfo.dept_id,
            organizationId: this.treeNode.id,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.showTreeDialog = false;
              this.treeForm = {};
              this.page.currentPage = 1;
              this.onload(this.page);
              // done()
            }
          }).catch((err) => {
              done();
            });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.leftQuarter {
  width: 200px;
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #ffffff;
  border-right: 1px solid #cccccc;
}

.riskClass {
  ::v-deep .common_tree_box .common_tree_handle {
    width: 240px;
  }
}

.quarterBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px solid #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 8px;
  position: relative;

  .deleteIcon {
    font-size: 16px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    color: #f56c6c;
  }
}

.quarterActiveClass {
  border: 1px solid #2979ff;
  background-color: #b3d8ff;
}

.quarterAddBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px dashed #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
}

.introduceClass ::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 421px) !important;
}

::v-deep .introduceTable .avue-crud .el-table {
  height: calc(100vh - 385px) !important;
  max-height: calc(100vh - 385px) !important;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}

.tagBox {
  position: absolute;
  left: 100px;
  top: 9px;
  z-index: 99;
}
</style>
