var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "div",
        { staticClass: "content", staticStyle: { position: "relative" } },
        [
          _c(
            "el-container",
            [
              _c("CommonTreeNew", {
                ref: "commonTree",
                attrs: {
                  treeTitle: "组织架构",
                  defaultProps: _vm.Props,
                  showCheckbox: false,
                  urlParmas: {
                    tenantId: "",
                    deptCategory: "5,2",
                    parentId: _vm.userInfo.dept_id,
                  },
                  "node-key": "id",
                  isShowdig: false,
                  isZoom: true,
                  show: _vm.show,
                },
                on: {
                  getNodeClick: _vm.handleNode,
                  showChange: _vm.showChange,
                },
              }),
              _c(
                "el-main",
                { staticStyle: { position: "relative" } },
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": "风险辨识",
                    },
                    on: {
                      "head-add": function ($event) {
                        return _vm.opDialog()
                      },
                      "head-delete": _vm.deletesList,
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchForm",
                    attrs: {
                      searchSpan: "4",
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.handleSearch,
                      "grid-head-empty": _vm.searchReset,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    attrs: {
                      "data-total": _vm.tableTotal,
                      page: _vm.page,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      tableOptions: _vm.tableOptions,
                    },
                    on: {
                      "page-size-change": _vm.handleSizeChange,
                      "page-current-change": _vm.handleCurrentChange,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customBtn",
                        fn: function ({ row, index }) {
                          return [
                            row.createUser == _vm.userInfo.user_id
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowEdit(row, index)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              编辑\n            ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkDetail(row, index)
                                  },
                                },
                              },
                              [_vm._v("\n              查看\n            ")]
                            ),
                            row.createUser == _vm.userInfo.user_id
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowDel(row, index)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              删除\n            ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "风险版本配置",
            "modal-append-to-body": false,
            visible: _vm.showTreeDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTreeDialog = $event
            },
          },
        },
        [
          _vm.showTreeDialog
            ? _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.handleSubmit },
                model: {
                  value: _vm.treeForm,
                  callback: function ($$v) {
                    _vm.treeForm = $$v
                  },
                  expression: "treeForm",
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.cancelTreeDialog },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeDialogLoading,
                      expression: "treeDialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTreeNodeSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }